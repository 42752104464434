/**
 * return ActivityContextEnum String from int
 * @param index int as string, eg: '4'
 * @returns {string | undefined}}
 */
export function getActivityContextString(index) {
  return new Map([
    ['0', 'HomeAssignments'],
    ['1', 'SchoolAssignments'],
    ['2', 'AdventureIsland'],
    ['3', 'Competition(Deprecated)'],
    ['4', 'TrainingZone'],
    ['5', 'Arena(Deprecated)'],
    ['6', 'ParentAssignments'],
    ['7', 'MyJourney'],
    ['8', 'PlacementTest'],
    ['9', 'MatificPlay'],
    ['10', 'MyFavorites'],
    ['11', 'Global'],
    ['12', 'AssignIsland'],
  ]).get(index);
}

class AjaxService {
    static RETRY_DELAY = 1000 * 2;

    constructor() {
    }

    static post(url, data, message, sendCSRFToken = false, retryNumber = 0) {
        let ajaxData = {
            url: url,
            type: "POST",
            data: JSON.stringify(data),
            dataType: "json",
            async: false,
            contentType: "application/json; charset=utf-8",
        }

        if (sendCSRFToken) {
            ajaxData["headers"] = {"X-CSRFToken": getCookie("csrftoken")}
        }

        return jQuery.ajax(ajaxData)
            .done(function (data) {
                console.log(message + " success")
                return data;
            })
            .fail(function (xmlhttprequest, textStatus, message) {
                console.log(message + " fail")
                if (retryNumber > 0 && xmlhttprequest.statusText.toLowerCase().indexOf('error') > -1 &&
                    xmlhttprequest.status == 0) {
                    // retry on connection error
                    setTimeout(() => {
                            return sendAjaxPOST(url, data, message, sendCSRFToken, retryNumber - 1);
                        },
                        AjaxService.RETRY_DELAY);
                }
                return xmlhttprequest
            });
    }

    static get(url, message) {
        return jQuery.ajax({
            url: url,
            type: "GET",
            async: false,
            contentType: "application/json; charset=utf-8",
        })
            .done(function (data) {
                console.log(message + " success")
                return data;
            })
            .fail(function (xmlhttprequest, textStatus, message) {
                console.log(message + " fail")
                return xmlhttprequest
            });
    }
    // this is required for api called in monolith
    // if we use credentials for lambda it fails with CORS issue
    // TODO: cleanup lambda to allow credentials.
    static get_with_credentials(url, message) {
        return jQuery.ajax({
            url: url,
            type: "GET",
            async: false,
            contentType: "application/json; charset=utf-8",
            xhrFields: {
              withCredentials: true
           },
        })
            .done(function (data) {
                console.log(message + " success")
                return data;
            })
            .fail(function (xmlhttprequest, textStatus, message) {
                console.log(message + " fail")
                return xmlhttprequest
            });
    }

    static getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie != '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = jQuery.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) == (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    static isRequestFail(res) {
        return res && res.hasOwnProperty("statusText") && res.statusText.toLowerCase().indexOf('error') > -1;
    }
}

/**
 * Provide constants for the type of messages (post-messages) that are used between the episode and its containing
 * envelope (either envelope episode or web client) and vice-versa.
 *
 * TODO the list of messages is not conclusive, we need to collect the various messages
 */

export class EpisodeControlEnum {
    static MESSAGES = {
        /**
         * Some episodes save their state data on the server and recover it when you start them.
         * The envelope episode (e.g. gamification) will query the server for the episode data while loading the episode
         * and before giving the episode a signal to start. The following set of messages supports this mechanism
         * they are used by envelope-episodes (STORE_EPISODE_DATA_REQUEST is used also by the EpisodeDataStorageService
         * within a regular episode).
         */
        Storage: Object.freeze({
            /**
             * A request to store data for an episode.
             * Here is an example of such message:
             * <pre>
             *     {
             *         "type": "StoreEpisodeDataRequest",
             *         "updateId": "some uuid"
             *         "slug": "ImaginaryEpisode",
             *         "data": {
             *         "score": 4542,
             *         "level": 3,
             *         "level2State": {
             *            "tapOrder": [2, 3, 1]
             *         },
             *         "irrelevantProperty": "$DELETE_PROPERTY"
             *     }
             * </pre>
             */
            STORE_EPISODE_DATA_REQUEST: "StoreEpisodeDataRequest",
            /**
             * A request to store data for all episodes.
             * Here is an example of such message:
             * <pre>
             *     {
             *         "type": "StoreEpisodeDataRequest",
             *         "updateId": "some uuid"
             *         "data": {
             *         "score": 4542,
             *         "level": 3,
             *         "level2State": {
             *         "tapOrder": [2, 3, 1]
             *         },
             *         "irrelevantProperty": "$DELETE_PROPERTY"
             *     }
             * </pre>
             */
            STORE_EPISODES_DATA_REQUEST: "StoreEpisodesDataRequest",

            STORE_EPISODES_DATA_RESPONSE: "StoreEpisodesDataResponse",
            /**
             * An acknowledgement from the web-envelope to the STORE_EPISODE_DATA_REQUEST. The message will have a status
             * success attribute with a value 'true' or 'false'
             * data attribute with synced episode state
             */
            STORE_EPISODE_DATA_RESPONSE: "StoreEpisodeDataResponse",
            /**
             * A request from an envelope-episode to the web-client to load the data stored for a given episode.
             * This request will be made while loading the episode but before giving it a signal to start.
             * The request will have a 'slug' attribute.
             */
            LOAD_EPISODE_DATA_REQUEST: "LoadEpisodeDataRequest",
            /**
             * A response (from web-client to envelope-episode) with the data stored for the episode.
             */
            LOAD_EPISODE_DATA_RESPONSE: "LoadEpisodeDataResponse",
        }),
        Recording: Object.freeze({
            /**
             * A message sent by the EventRecordingManager carrying data about a recording of user interaction with the episode.
             * Usually it is sent upon FinishEpisode if the episode was run with recording (EpisodeParameters.USABILITY=true).
             * If the episode is run inside a wrapper (during development) you can also invoke it manually.
             * When this message arrive to the envelope of the episode, it passes the recording to an end-point in the server
             * that stores the recorded sessions.
             */
            EPISODE_RECORDED_DATA: "EpisodeRecordingData",
        }),
        /**
         * Tracking messages are messages about events in the episode that are meant mainly for analysis. Some of them
         * are passed to MixPanel (based on filtering configuration) and to the fact-table. In addition some of them might
         * also have effect in the envelope or web client.
         * All tracking events are sent to the envelope and from there to the web-client as a similar message of type
         * 'Tracking', but it has a 'data' attribute which hold the detailed event that has the tracking type of the event
         * in both attributes 'type' and 'eventType'.
         * The following types refer to the detailed tracking types.
         */
        Tracking: Object.freeze({
            TRACKING: "Tracking",
            START_EPISODE: "StartEpisode",
            PRESENT_PROBLEM_INTRO: "PresentProblemIntro",
            PRESENT_PROBLEM: "PresentProblem",
            SUBMIT_SOLUTION: "SubmitSolution",
            COMPLETED_PROBLEM: "CompletedProblem",
            EPISODE_PROGRESS_UPDATE: "EpisodeProgressUpdate",
            FINISH_EPISODE: "FinishEpisode",
            ABORT_EPISODE: "AbortEpisode",
            EPISODE_READY: "EpisodeReady",
            RESET_PROBLEM: "ResetProblem",
            MACHINE_VOICEOVER_MISSING: "MachineVoiceoverMissing",
            SUBMIT_ASSESSMENT: "AssessmentSubmit",
            ASSESSMENT_ABORTED: "AssessmentAborted",
            ASSESSMENT_STARTED: "AssessmentStarted",
            ASSESSMENT_FINISHED: "AssessmentFinished",
            PLACEMENT_TEST_SUBMIT: "PlacementTestSubmit",
            CLOSE_EPISODE_REQUEST: "CloseEpisodeRequest",
            //Arena
            ARENA_ROUND_PRESENTED: "ArenaRoundPresented",
            ARENA_ROUND_FINISHED: "ArenaRoundFinished",
            ARENA_ROUND_SUBMITTED: "ArenaRoundSubmitted",
            //APP
            EPISODE_ENGAGEMENT: "EpisodeEngagement",
            EPISODE_LOADING_STARTED: "EpisodeLoadingStarted",
            EPISODE_RUNNING: "EpisodeRunning",
            EPISODE_INTERACTION: "EpisodeInteraction",
            FIRST_INTERACTION: "FirstInteractionInProblemOccurred",
        }),
        Development: Object.freeze({
            SSML_EDIT_REQUEST: "SsmlEditRequest",
            INSPECT_ELEMENT_RESULT: "InspectElementResult",
        }),
        EpisodeFlow: Object.freeze({
            /** This event can be sent from the browser envelope to the episode manager to support the following scenario:
             typically when the browser gives the iframe of the episode the url of the episode, the episode loads and
             begins to play immediately. However the browser can pass in the url a 'suspend' flag that will allow the
             episode to load all classes and resources but suspend before calling the setup() method and starting the
             episode. This allows the browser to prepare the episode (on slow networks) and run it immediately when needed.
             This can be used for example in playlists or in assesments in which we want to play episodes one after the
             other.*/
            START_SUSPENDED_EPISODE: "StartSuspendedEpisode",
            EPISODE_INIT_MESSAGE: "EpisodeInitMessage",
            MOVE_TO_PROBLEM: "MoveToProblem",
            EPISODE_BROKEN: "EpisodeBroken",
            EPISODE_ENGAGEMENT: "EpisodeEngagement",
        }),
        Commands: Object.freeze({
            /**
             * A request to execute a certain SlateCommand. For example a request to execute an AddEntityCommand thus in
             * effect make the episode present a certain UI
             */
            EXECUTE_COMMAND: "ExecuteCommand",
        }),
        Audio: Object.freeze({
            /**
             * A request from the episode to the envelope to play a sound on its behalf. We use this in the web when we
             * want to play a sound from within the episode before the user made any interaction, normally the browser
             * will block the sound if played directly by the episode so we request the envelope in whose iFrame clicks
             * have already been made to play the sound for us. See AudioRestrictionPolicy for more info.
             */
            PLAY_SOUND_REQUEST: "PlaySoundRequest",
            /**
             * A request from the episode to the envelope to update the properties (usually volume) of a sound the
             * envelope plays on behalf of the episode. See AudioRestrictionPolicy for more info.
             */
            UPDATE_SOUND_REQUEST: "UpdateSoundRequest",
            /**
             * A request from the episode to the envelope to stop a sound the
             * envelope was previously requested to play on behalf of the episode. See AudioRestrictionPolicy for more info.
             */
            STOP_SOUND_REQUEST: "StopSoundRequest",
            /**
             * A request from the episode to the envelope or vice versa to change the state of the audio muting.
             */
            CHANGE_MUTE_STATE_REQUEST: "ChangeMuteStateRequest",
            MUTE: "mute",
            UNMUTE: "unmute",
            MUTE_EPISODE: "MuteEpisode",
            UNMUTE_EPISODE: "UnMuteEpisode"
        }),
    };

    static EpisodeType = Object.freeze({
        EPISODE: "Episode",
        PLACEMENT_TEST: "PlacementTest"
    });

    static AssignmentType = Object.freeze({
        PARENT: "0",
        CLASS: "1",
        HOMEWORK: "2"
    });

    static AppMessage = Object.freeze({
        EPISODE_CONTAINER_LOADED: "EpisodeContainerLoaded",
    });
}

class EventRecordService {
    static EVENT_RECORD_ENDPOINT = 'https://ljifg6p8cd.execute-api.us-east-1.amazonaws.com/production/matific-stat';

    static sendRecording(event, slug) {
        const data = {
            'slug': slug,
            'run_id': event.episodeSessionId,
            'stat': JSON.stringify(event.script)
        };
        AjaxService.post(EventRecordService.EVENT_RECORD_ENDPOINT, data, "send recording")
    }
}

export class FactsService {
    static FACTS_FIELDS_TO_OMIT = ['klass_id', 'teacher_id', 'user_type'];
    static INCLUDE_FACTS = [
        EpisodeControlEnum.MESSAGES.Tracking.START_EPISODE,
        EpisodeControlEnum.MESSAGES.Tracking.EPISODE_PROGRESS_UPDATE,
        EpisodeControlEnum.MESSAGES.Tracking.FINISH_EPISODE,
        EpisodeControlEnum.MESSAGES.Tracking.ABORT_EPISODE,
        EpisodeControlEnum.MESSAGES.Tracking.PRESENT_PROBLEM_INTRO,
        EpisodeControlEnum.MESSAGES.Tracking.SUBMIT_SOLUTION,
        EpisodeControlEnum.MESSAGES.Tracking.SUBMIT_ASSESSMENT,
        EpisodeControlEnum.MESSAGES.Tracking.ASSESSMENT_ABORTED,
        EpisodeControlEnum.MESSAGES.Tracking.ASSESSMENT_STARTED,
        EpisodeControlEnum.MESSAGES.Tracking.ASSESSMENT_FINISHED,
        EpisodeControlEnum.MESSAGES.Tracking.PLACEMENT_TEST_SUBMIT,
        EpisodeControlEnum.MESSAGES.Tracking.EPISODE_ENGAGEMENT,
        EpisodeControlEnum.MESSAGES.Tracking.COMPLETED_PROBLEM,
    ];

    fireBaseLoadPromise;
    factBuffer = [];
    eventBuffer = [];

    episodeTimings = {
        episodeStartTime: null,
        submissionCount: 0,
        problemCompleted: 0
    };

    RECORD_EPISODE_FACT_URL;
    RECORD_PLACEMENT_TEST_FACT_URL;
    token;

    abortEventDelayTimer;
    keepAliveTimer;
    roomRef;
    competition_id;

    constructor(token, addFactsEndpoint, recordPlacementEndpoint, firebaseConfig, competitionId=null) {
        console.log("new FactsService()", BUILD_VERSION);
        this.token = token;
        this.RECORD_EPISODE_FACT_URL = addFactsEndpoint;
        this.RECORD_PLACEMENT_TEST_FACT_URL = recordPlacementEndpoint;
        this.competition_id = competitionId;

        if (!firebaseConfig) {
            this.factBuffer = undefined;
            this.eventBuffer = undefined;
        } else {
            const firebaseSettings = {
                user_token: firebaseConfig.token,
                is_multi_enabled: firebaseConfig.is_multi_enabled
            };
            if (firebaseConfig.firebase_config) {
                firebaseSettings.firebase_region = firebaseConfig.firebase_region;
                firebaseSettings.firebase_config = firebaseConfig.firebase_config;
            }
            this.fireBaseLoadPromise = PlayerService.loadFireBase(
                firebaseConfig.script_url,
                firebaseSettings,
                firebaseConfig['userId'], firebaseConfig.hasOwnProperty('userId')).then(success => {
                this.setRoomRef(firebaseConfig.room_path, firebaseConfig.aircraftUrl);

                if (this.factBuffer && this.factBuffer.length > 0) {
                    this.sendFirebaseFacts(this.factBuffer);
                }
                if (this.eventBuffer && this.eventBuffer.length > 0) {
                    this.eventBuffer.map((event, i) => {
                        const {type, data} = event;
                        if (type) {
                            this.sendGoogleAnalyticsEvent(type, data);
                        }
                        if (i === this.eventBuffer.length - 1) {
                            this.eventBuffer = undefined;
                        }
                    });
                }
                this.factBuffer = undefined;
                return this.roomRef;
            }, () => {
                this.factBuffer = undefined;
                this.eventBuffer = undefined;
                return Promise.resolve(null);
            });
        }
    }

    isBufferEnable(buff) {
        return buff && buff.length >= 0;
    }

    setRoomRef(roomPath, aircraftUrl) {
        if (roomPath) {
            try {
                this.roomRef = PlayerService.getRoomRef(roomPath);
                if (this.roomRef) {
                    this.roomRef.get().then(result => {
                        const roomData = result && result.data() || null;
                        PlayerService.setRoomData(roomData, aircraftUrl);
                    });
                }
                PlayerService.watchAllPlayers(this.roomRef);
            } catch (e) {
            }
        }
        return undefined;
    }

    updateRoomData(roomData) {
        PlayerService.setRoomData(roomData);
    }

    sendGoogleAnalyticsEvent(eventType, analyticData) {
        if ((!PlayerService.firestore || !PlayerService.firestore.app) && eventType && eventType !== '') {
            if (this.isBufferEnable(this.eventBuffer)) {
                this.eventBuffer.push({type: eventType, data: analyticData});
            }
        } else {
            PlayerService.firestore.logEvent(eventType, analyticData);
        }
    }

    sendEpisodeFacts(event, episodeData) {
        const events = event.isBulk ? (event.data || []) : [event];
        const eventsToTrack = events.filter(event => !!event).map(event => ({eventName: event.type, data: event}));
        this.trackMultiple(eventsToTrack, episodeData);
    }

    trackMultiple(events, episodeData) {
        const episodeFacts = events.reduce((output, event) => {

            const extraData = {
                triggeredFrom: this.getTriggeredFrom(event.data),
                eventLabel: event.eventLabel,
                eventValue: event.eventValue,
                path: location.href,
            };
            const eventData = {
                ...event.data,
                ...episodeData,
                devName: event.data.data ? event.data.data.episodeName : undefined,
            }
            if (this.filterOutEvents(event.eventName, eventData)) {
                return output;
            }
            const {name, withExtraData} = this.convertEventData(event.eventName, eventData, extraData);
            if (withExtraData && (withExtraData['eventName'] === 'MachineVoiceoverMissing' || name === 'MachineVoiceoverMissing')) {
                try {
                    sendVoiceOverData(withExtraData['eventData'] || withExtraData)
                } catch (e) {
                }
            }

            const episodeFact = this.buildFact(name, withExtraData);

            if (episodeFact) {
                output.push(episodeFact);
            }

            return output;
        }, []);

        if (episodeFacts.length > 0) {
            return this.recordMultipleFacts(episodeFacts);
        }
    }

    filterOutEvents(name, eventData) {
        return (name === 'Tracking' &&
            eventData.data &&
            eventData.data.eventType === 'StartEpisode' &&
            eventData.data.episodeName === '$EPISODE_NAME$');
    }

    getTriggeredFrom(eventData) {
        return eventData ? (eventData.data ?
            eventData.data['tileSection'] :
            eventData['tileSection']) : undefined;
    }

    convertEventData(name, eventData, extraData) {
        let unboxedEventData = eventData || {};
        if (eventData && eventData.hasOwnProperty('type') && eventData.type === "Tracking" &&
            eventData.hasOwnProperty('data') && eventData.data && typeof eventData.data === 'object') {
            unboxedEventData = {...eventData, ...eventData.data};
            delete unboxedEventData['data'];
        }

        let newNameForTrackingEvent = name;
        if (name === 'Tracking') {
            newNameForTrackingEvent = unboxedEventData['eventType'] || unboxedEventData['type'] || name;
        }

        let withExtraData = {...unboxedEventData, ...extraData};
        Object.keys(withExtraData).map(key => {
            if (UtilService.isNil(withExtraData[key])) {
                delete withExtraData[key]
            }
        });

        return {name: newNameForTrackingEvent, withExtraData};
    }

    buildFact(name, event) {
        if (!FactsService.INCLUDE_FACTS.includes(name)) {
            return null;
        }

        let fact = event;

        if (typeof event.factData === 'object') {

            fact = {...event, ...event.factData};
            delete event.factData;
            delete fact.factData;
        }

        var keyMapping = {
            slug: 'episode_slug',
            eventType: 'type',
            classId: 'klass_id',
            userGradeCode: 'grade_code',
            loadingStartTime: 'load_time_sec',
            correct: 'is_correct',
            sinceStart: 'since_episode_start_sec',
            sincePresentProblem: 'since_question_start_sec',
            stars: 'score',
            episodeSessionId: 'episode_run_discriminator',
            score: 'points',
            PlatformVersion: 'version',
            DBVersion: 'DB_version',
        };

        var valueMapping = {
            is_correct: (v) => (v ? 1 : 0),
            since_question_start_sec: (v) => ((typeof (v) === "number") ? Math.round(v / 1000) : v),
        };

        // For each key:value in the fact - replace the key with keyMapping/snakeCase,
        // and replace the value with valueMapping function result
        fact = Object.keys(fact).reduce((output, key) => {
            const value = fact[key];
            const newKey = keyMapping[key] ? keyMapping[key] : UtilService.snakeCase(key);
            output[newKey] = valueMapping[newKey] ? valueMapping[newKey](value) : value;
            return output;
        }, {});

        fact.client_time = this.timestamp;
        fact.time_diff = ('time' in fact) ? (fact.client_time - fact.time) : 0;
        fact.from_tablet = false;

        if (name === EpisodeControlEnum.MESSAGES.Tracking.COMPLETED_PROBLEM) {
            PlayerService.updateEpisodeProgress(fact);

            if (this.roomRef) {
                PlayerService.sendRoomProgressToFirebase(this.roomRef);
            }
            return;
        }

        let eventTypeHandlers = {
            StartEpisode: (fact, now) => {
                if (fact.load_time_sec) {
                    fact.load_time_sec = now - fact.load_time_sec;
                }
                this.episodeTimings.episodeStartTime = now;
                return fact;
            },
            SubmitSolution: (fact, now) => {
                this.episodeTimings.submissionCount = (this.episodeTimings.submissionCount || 0) + 1;
                if (fact.is_correct) {
                    this.episodeTimings.problemCompleted = (this.episodeTimings.problemCompleted || 0) + 1;
                }
                fact.attempt = (fact.mistakes || 0) + (fact.is_correct ? 1 : 0);
                return fact;
            },
            FinishEpisode: (fact, now) => {
                fact.episode_duration = this.episodeTimings.episodeStartTime ? now - this.episodeTimings.episodeStartTime : null;
                this.episodeTimings.episodeStartTime = undefined;
                return fact;
            },
            AbortEpisode: (fact, now) => {
                fact.episode_duration = this.episodeTimings.episodeStartTime ? now - this.episodeTimings.episodeStartTime : null;
                fact.since_episode_start_sec = this.episodeTimings.episodeStartTime ? fact.episode_duration / 1000 : null;
                this.episodeTimings.episodeStartTime = undefined;
                this.episodeTimings.submissionCount = (this.episodeTimings.submissionCount || 0) + 1;
                this.episodeTimings.problemCompleted = (this.episodeTimings.problemCompleted || 0) + 1;
                return fact;
            },
            EpisodeProgressUpdate: (fact, now) => fact,
        };

        if (eventTypeHandlers.hasOwnProperty(fact.type) && eventTypeHandlers[fact.type]) {
            fact = eventTypeHandlers[fact.type](fact, fact.client_time)
        }
        PlayerService.updateEpisodeProgress(fact);

        // console.log('FactService::: ', fact);
        return fact;
    }

    clearAbortTimeout() {
        if (this.abortEventDelayTimer) {
            clearTimeout(this.abortEventDelayTimer);
            this.abortEventDelayTimer = undefined;
        }
    }

    get timestamp() {
        return new Date().getTime();
    }

    sendCloseEpisodeToUnity(facts, failedRes) {
        this.clearAbortTimeout();
        try {
            if (failedRes) {
                if (failedRes.status === 0) {
                    facts.forEach(fact => {
                        if ([EpisodeControlEnum.MESSAGES.Tracking.ABORT_EPISODE, EpisodeControlEnum.MESSAGES.Tracking.FINISH_EPISODE].indexOf(fact.type) > -1) {
                            sendLostConnectionMessageToApp()
                        }
                    })
                } else {
                    facts.forEach(fact => {
                        if ([EpisodeControlEnum.MESSAGES.Tracking.EPISODE_PROGRESS_UPDATE, EpisodeControlEnum.MESSAGES.Tracking.FINISH_EPISODE].indexOf(fact.type) > -1) {
                            sendAbortMessageToApp("DataDidNotSave");
                        }
                    })
                }
            } else {
                const fact = facts.find(fact => fact.type === EpisodeControlEnum.MESSAGES.Tracking.ABORT_EPISODE);
                if (fact) {
                    sendAbortMessageToApp("UserAbort", fact.since_episode_start_sec);
                }
            }
        } catch (e) {
        }
    }

    recordMultipleFacts(facts) {
        facts = facts.map(item => this.cleanFact(item));
        let allFacts = [];
        let placementTestSubmitFacts = [];
        facts.forEach(fact => {
            if (fact.type === 'PlacementTestSubmit') placementTestSubmitFacts.push(fact)
            else allFacts.push(fact)
        })

        let factFailedRes;

        // console.log('recordMultipleFacts', facts);
        console.log('competition_id', this.competition_id);
        const episodeFacts = facts.filter(fact => (!fact['firebaseOnly'] && !fact['firebase_only']));
        if (this.RECORD_PLACEMENT_TEST_FACT_URL && placementTestSubmitFacts.length > 0) {
            const PlacementTestReqobj = {
                'fact': placementTestSubmitFacts[0],
                'user_data_token': this.token
            };
            if(this.competition_id) {
                PlacementTestReqobj['fact']['competition_id'] = this.competition_id;
            }
            AjaxService.post(this.RECORD_PLACEMENT_TEST_FACT_URL, PlacementTestReqobj, "record episode fact", false, 2)
        } else if (this.RECORD_EPISODE_FACT_URL && episodeFacts.length > 0) {
            if (PlayerService.quickSightReportData) {
                episodeFacts[0] = Object.keys(PlayerService.quickSightReportData).reduce((output, key) => {
                    output[key] = PlayerService.quickSightReportData[key];
                    return output;
                }, episodeFacts[0]);
            }
            const reqObj = {'facts': episodeFacts, 'user_data_token': this.token};
            if (this.competition_id) {
                reqObj['facts'] = episodeFacts.map(data => ({
                    ...data,
                    'competition_id': this.competition_id
                }));
            }
            const res = AjaxService.post(this.RECORD_EPISODE_FACT_URL, reqObj, "record episode fact", false, 2)
            if (AjaxService.isRequestFail(res)) {
                factFailedRes = res;
            }
        }

        this.sendFirebaseFacts(allFacts, factFailedRes);
    }

    cleanFact(fact) {
        return FactsService.FACTS_FIELDS_TO_OMIT.reduce((fact, key) => {
            delete fact[key];
            return fact;
        }, fact);
    }

    sendFirebaseFacts(allFacts, failedRes) {
        if (!PlayerService.firestore || !PlayerService.firestore.isReady) {
            if (this.isBufferEnable(this.factBuffer)) {
                console.log("FirestoreService::saveFacts::Service is not ready, deferring...");
                this.factBuffer.push(...allFacts);
            }
            return;
        }

        const facts = allFacts && allFacts.filter(f => (PlayerService.LIVE_CLASSROOM_TRACKING_FACTS.indexOf(f.type) >= 0));
        if (PlayerService.firestore.matificUser && facts && facts.length > 0) {
            PlayerService.sendRoomProgressToFirebase(this.roomRef);

            const klassId = PlayerService.klassId;
            const userId = PlayerService.userId;
            if (!userId || !klassId) { //live classroom update need classId + userId
                console.log("FirestoreService::saveFacts missing klass_id OR user_id");
                this.sendCloseEpisodeToUnity(allFacts, failedRes);
                return;
            }
            console.log("FirestoreService::saveFacts[" + klassId + "]", facts);
            const batch = PlayerService.setEpisodeEventToFirebase(facts, klassId, userId, this.timestamp);
            batch.commit().then(() => {
                if (!this.keepAliveTimer) {
                    this.keepAliveTimer = setInterval(() => {
                        console.log("FirestoreService - Keep Alive ");
                        PlayerService.keepFirebaseAlive(klassId, this.timestamp);
                    }, 15000);
                }
                this.sendCloseEpisodeToUnity(allFacts, failedRes);
            });

            this.abortEventDelayTimer = setTimeout(() => {
                console.log("FirestoreService::setTimeout()... ", facts);
                this.sendCloseEpisodeToUnity(allFacts, failedRes);
            }, 3000);
        } else {
            console.log("FirestoreService - exit without sending to Firebase", facts);
            this.sendCloseEpisodeToUnity(allFacts, failedRes);
        }
    }
}

export class PlayerService {
    static FIRESTORE_SCRIPT_ID = 'matific-firestore-service'; // same as teachers-site, episode-container and matific-play
    static FACT_EXPIRY_TIME = 120 * 60000; // 2 hrs

    static LIVE_CLASSROOM_TRACKING_FACTS = [
        EpisodeControlEnum.MESSAGES.Tracking.START_EPISODE,
        EpisodeControlEnum.MESSAGES.Tracking.FINISH_EPISODE,
        EpisodeControlEnum.MESSAGES.Tracking.ABORT_EPISODE,
        EpisodeControlEnum.MESSAGES.Tracking.SUBMIT_SOLUTION
    ];

    static PROGRESS_TRACKING = [
        EpisodeControlEnum.MESSAGES.Tracking.START_EPISODE,
        EpisodeControlEnum.MESSAGES.Tracking.FINISH_EPISODE,
        EpisodeControlEnum.MESSAGES.Tracking.ABORT_EPISODE,
        EpisodeControlEnum.MESSAGES.Tracking.SUBMIT_SOLUTION,
        EpisodeControlEnum.MESSAGES.Tracking.COMPLETED_PROBLEM,
        // EpisodeControlEnum.MESSAGES.Tracking.PRESENT_PROBLEM,
    ]

    static BASE_EPISODE_FIELDS = ["episode_slug", "problem_count", "episode_name",
        "episode_type", "activity_context", "origin_id", "client_time", "type"];
    static episodeProgressDict = {};
    static lastEpisodeItem;
    static maxScore = -1;
    static questionAnswerTime = -1;
    static remainingQuestion = -1;
    static playerIndexCount = 0;

    static PROGRESS_BAR = {
        PANEL: 'progress-bar-panel',
        USER_CONTAINER_ID: 'progress-user-container',
        CONTAINER: 'progress-bar-container',
        COMPONENT: 'player-progress-bar',
        COMPONENT_BG: 'player-progress-bar-bg',

        QUESTION: {
            MAX_SEC: 300,
            MAX_ACCURACY: 300
        }
    }

    static BOT_CONFIG = {
        'SUPER BOT': {
            type: 'SUPER BOT',
            time_range: [0.8, 0.95], //[min, max]
            accuracy_range: [0.8, 1], // 4-5 stars,
            stars_range: [4, 5],
            time_ratio: 0,
            accuracy: 0,
            stars: 0
        },
        'SLOW SMART BOT': {
            type: 'SLOW SMART BOT',
            time_range: [1.2, 1.4], //[min, max]
            accuracy_range: [1], // 5 stars
            stars_range: [5],
            time_ratio: 0,
            accuracy: 0,
            stars: 0
        },
        'FAST BOT': {
            type: 'FAST BOT',
            time_range: [0.7, 0.85], //[min, max]
            accuracy_range: [0.2, 0.4], // 1-2 stars
            stars_range: [1, 2],
            time_ratio: 0,
            accuracy: 0,
            stars: 0
        },
        'DUMB BOT': {
            type: 'DUMB BOT',
            time_range: [0.9, 1.1], //[min, max]
            accuracy_range: [0.4, 0.6], // 2-3 stars
            stars_range: [2, 3],
            time_ratio: 0,
            accuracy: 0,
            stars: 0
        },
        'YEDI': {
            type: 'YEDI',
            time_range: [1, 1.3], //[min, max]
            accuracy_range: [0.8, 1], // 4-5 stars
            stars_range: [4, 5],
            time_ratio: 0,
            accuracy: 0,
            stars: 0
        },
    }

    static FIREBASE_RECORD_MODE_ENUM = {
        FACT_EVENTS: 'fact-events',
        EPISODE_RESULTS: 'episode-results',
        BOTH: 'both'
    }
    static FIREBASE_RECORD_MODE = this.FIREBASE_RECORD_MODE_ENUM.BOTH;

    static keepFirebaseAlive(klass_id, timestamp) { // add on expires_at
        const expires_at = this.firestore.getTimestamp((timestamp + this.FACT_EXPIRY_TIME) / 1000, 0);

        if (this.FIREBASE_RECORD_MODE === this.FIREBASE_RECORD_MODE_ENUM.EPISODE_RESULTS) {
            this.liveclassFirestore.doc(klass_id).collection('episode-results').doc('keep-alive').set(
                {'keep-alive': {'expires_at': expires_at}})
        } else {
            this.liveclassFirestore.doc(klass_id).collection('fact-events').doc('keep-alive').set(
                {'keep-alive': {'expires_at': expires_at}})
        }
    }

    static setEpisodeEventToFirebase(facts, klass_id, user_id, timestamp) {
        const expires_at = this.firestore.getTimestamp((timestamp + this.FACT_EXPIRY_TIME) / 1000, 0);
        const batch = this.firestore.db.batch();
        if (this.FIREBASE_RECORD_MODE !== this.FIREBASE_RECORD_MODE_ENUM.EPISODE_RESULTS) { // fact events
            facts.forEach(fact => {
                const factEvent = this.convertFirebaseEventItem(fact, klass_id, user_id, expires_at);
                console.log("FirestoreService::saveFact", klass_id, fact, factEvent);
                batch.set(
                    this.liveclassFirestore.doc(klass_id).collection('fact-events').doc(),
                    factEvent,
                );
            });
        }

        if (this.FIREBASE_RECORD_MODE !== this.FIREBASE_RECORD_MODE_ENUM.FACT_EVENTS && this.lastEpisodeItem) {// episode-results
            this.lastEpisodeItem.student_id = user_id;
            this.lastEpisodeItem.expires_at = expires_at;

            const episodeResultsRef = this.liveclassFirestore.doc(klass_id).collection('episode-results');
            const episodeUniqueKey = this.getEpisodeDictKey(this.lastEpisodeItem);
            const lastEpisodeStudentKey = `${user_id}-${episodeUniqueKey}`;

            try {
                console.log(`PlayerService::: save episode-results:[${lastEpisodeStudentKey}]`, this.lastEpisodeItem);
                episodeResultsRef.doc(lastEpisodeStudentKey).set(this.lastEpisodeItem, {merge: true});
            } catch (e) {
                console.log('PlayerService::: save episode-results error:', e);
            }
        }
        return batch;
    }

    static aircraftUrl;
    static setRoomData(roomData, aircraftUrl = null) {
        if(aircraftUrl && !this.aircraftUrl) {
            this.aircraftUrl = aircraftUrl;
        }

        this.roomData = roomData;
        console.log('PlayerService::: setRoomData:', roomData);
        if (this.roomData) {
            const episodeAvgMinute = this.roomData['averageTime'] || this.roomData['avg_time'] || 0;
            this.updateBotConfig(this.roomData['roomCode'], episodeAvgMinute);

            const hostFrom = roomData.hasOwnProperty('host') && roomData['host'] || 'student';
            this.updateQuickSightReportData(this.QUICKSIGHT_FIELDS.HOST_FROM, hostFrom);
            const privateRoom = roomData['privateRoom'];
            this.updateQuickSightReportData(this.QUICKSIGHT_FIELDS.HOST_USERID.PRIVATE_ROOM, privateRoom);
            const roomLocale = roomData['region']+'/'+roomData['locale'];
            this.updateQuickSightReportData(this.QUICKSIGHT_FIELDS.ROOM_LOCALE, roomLocale);
        }
    }

    static playerStudentAvatars;
    static setPlayersStudentAvatar(playerAvatars) {
        this.playerStudentAvatars = playerAvatars;
    }

    static bots_progress;

    static sendRoomProgressToFirebase(roomRef) {
        const userID = this.userId;
        if (!roomRef || !userID || !this.lastEpisodeItem) {
            return;
        }

        const playerRef = this.getPlayerRef(roomRef, userID);
        if (playerRef) {
            try {
                const data = {
                    'client_time': this.lastEpisodeItem.client_time,
                    'last_event_type': this.lastEpisodeItem.type
                };

                if (this.lastEpisodeItem.type !== EpisodeControlEnum.MESSAGES.Tracking.START_EPISODE) {
                    data['playTime'] = this.lastEpisodeItem.playTime;
                    data['progressCompletion'] = this.lastEpisodeItem.progressCompletion;

                    if (this.allowProgressBar && (
                        this.lastEpisodeItem.type === EpisodeControlEnum.MESSAGES.Tracking.COMPLETED_PROBLEM ||
                        this.lastEpisodeItem.type === EpisodeControlEnum.MESSAGES.Tracking.FINISH_EPISODE ||
                        this.lastEpisodeItem.type === EpisodeControlEnum.MESSAGES.Tracking.ABORT_EPISODE)
                    ) {
                        data['progressScore'] = this.lastEpisodeItem.progressScore;
                        data['accuracyScore'] = this.lastEpisodeItem.accuracy_score;
                        if (this.bots_progress) {
                            data['botsProgress'] = Object.values(this.bots_progress).map(bot => {
                                let botTimeSpent = bot.time_spent;
                                let botProgressScore = bot.progressScore;
                                /**
                                    When player completes, UI will send the botsProgress to firebase
                                    the timeSpend and progressScore should be the final score and time
                                    the bot will get. SMWEB3-10075 & SMWEB3-10076
                                */
                                if(bot.progressCompletion < 100) {
                                    botTimeSpent = bot.totalTimeSpent;
                                    botProgressScore = bot.totalProgressScore;
                                }
                                return {
                                    uuid: bot.uuid,
                                    score: bot.score,
                                    timeSpent: botTimeSpent,
                                    progressScore: botProgressScore
                                }
                            });
                        }
                    }
                }

                console.log(`PlayerService::: sendRoomProgressToFirebase:[${userID}]`, this.allowProgressBar, data);
                playerRef.update(data, {merge: true});
            } catch (e) {
                console.log('PlayerService::: sendRoomProgressToFirebase failed', e);
            }
        } else {
            console.log('PlayerService::: sendRoomProgressToFirebase failed invalid playerRef')
        }
    }

    static watchAllPlayers(roomRef) {
        if (roomRef) {
            console.log('PlayerService::: watchAllPlayers');
            roomRef.collection('players').onSnapshot((players) => {
                if (players && players.docs && players.docs.length > 1) {
                    const playerList = players.docs.filter(p => p.id !== "info").map(player => {
                        const playerData = player.data();
                        playerData.uid = playerData.uuid && playerData.uuid === '' ? player.id : playerData.uuid;
                        return playerData;
                    });
                    this.renderPlayerList(playerList);
                }
            }, (e) => {
                console.log('PlayerService::: watchAllPlayers error', e);
            });
        } else {
            console.log('PlayerService::: watchAllPlayers failed', roomRef);
        }
    }

    static getEpisodeDictKey(fact) {
        return `${fact.episode_slug}-${fact.origin_id}`;
    }

    static createPlayerEpisode(fact) {
        const uniqueId = this.getEpisodeDictKey(fact);
        if (!this.episodeProgressDict.hasOwnProperty(uniqueId)) {
            this.episodeProgressDict[uniqueId] =
                this.BASE_EPISODE_FIELDS.reduce((output, key) => {
                    if (fact.hasOwnProperty(key)) {
                        output[key] = fact[key];
                        if (key === 'problem_count') {
                            /**
                             * Formula from PO requirement SMWEB3-9518:
                             * https://docs.google.com/document/d/10UMwLjrSD-JxM3FRy_rDpShlbkSuK_ETECqZoYjDc8A/edit
                             * Accuracy score + Time score
                             * @type {number}
                             */
                            output['progressScore'] = 0;
                            /**
                             * question index completion
                             * to indicate whether user complete the activity
                             */
                            output['progressCompletion'] = 0;
                            output['playTime'] = 0;
                            output['last_grade_count'] = 0;
                            output['time_score'] = 0;
                            output['accuracy_score'] = 0;
                            output['answer_count'] = 0;
                            output['bestPlayTime'] = 0; // best player time
                            const totalNum = Number(fact[key]);

                            if (totalNum > 0) {
                                this.updateMaxScore(totalNum);
                                output['answers'] = Array(totalNum).fill('');
                            } else {
                                if (fact.hasOwnProperty('is_correct')) {
                                    output['is_correct'] = fact.is_correct;
                                    output['attempt'] = fact.attempt;
                                }
                            }
                        } else if (key === 'client_time') {
                            output['start_time'] = fact[key];
                        }
                    }
                    return output;
                }, {});
        }

        const updateItem = this.episodeProgressDict[uniqueId];

        if (!this.lastEpisodeItem) {
            this.lastEpisodeItem = updateItem;
            console.log('PlayerService::: createPlayerEpisodeItem: ', updateItem, this.maxScore);
        } else if (fact.client_time > this.lastEpisodeItem.client_time) {
            this.lastEpisodeItem = updateItem;
        }
        return updateItem;
    }

    static getTimeScore(playTime) {
        let timeScore = 0;
        if (playTime < 1000) { // 1 sec
            timeScore = this.PROGRESS_BAR.QUESTION.MAX_SEC;
        } else if (playTime < 15000) { // 15 sec
            timeScore = this.PROGRESS_BAR.QUESTION.MAX_SEC - 20 * playTime / 3000;
        } else if (playTime < 100000) { // 100 sec
            timeScore = (100000 - playTime) / 425;
        }

        return Math.round(timeScore);
    }

    static updateMaxScore(questionCount = -1) {
        const {MAX_SEC, MAX_ACCURACY} = this.PROGRESS_BAR.QUESTION;
        if (questionCount >= 0) {
            this.remainingQuestion = questionCount;
        }

        const progressScore = this.lastEpisodeItem && this.lastEpisodeItem.progressScore || 0;
        const max_score_per_question = MAX_SEC + MAX_ACCURACY;
        let timeScore;
        let timeConsider = this.questionAnswerTime > 0 ? Math.round(this.questionAnswerTime / 4): 0;
        if (timeConsider >= 1000) {
            timeScore = this.getTimeScore(timeConsider);
            if (this.remainingQuestion > 0) {
                this.maxScore = progressScore + (this.remainingQuestion - 1) * max_score_per_question + MAX_ACCURACY + timeScore;
            } else {
                this.maxScore = progressScore;
            }
        } else if (this.remainingQuestion > 0) {
            this.maxScore = progressScore + this.remainingQuestion * max_score_per_question;
        }
        console.log('PlayerService::: maxScore', this.maxScore, progressScore, this.remainingQuestion,
            this.questionAnswerTime, timeConsider, timeScore);
    }

    static updateEpisodeProgress(fact) {
        if (!fact || !fact.type || this.PROGRESS_TRACKING.indexOf(fact.type) < 0) {
            return;
        }
        const episodeDetail = this.createPlayerEpisode(fact);
        switch (fact.type) {
            case EpisodeControlEnum.MESSAGES.Tracking.SUBMIT_SOLUTION:
                episodeDetail.type = fact.type;

                let answerItem = null, question_index = -1, step_answers;
                // create new answer or get answer item by question index
                if (episodeDetail.problem_count > 0 && fact.problem_index < episodeDetail.problem_count) {
                    question_index = fact.problem_index;
                    answerItem = episodeDetail.answers[question_index];
                }

                if (!answerItem || answerItem === '') { // create
                    answerItem = {
                        index: fact.problem_index,
                        step_count: fact.step_count,
                        input_attempts: 1,
                    }
                    if (fact.step_count > 1) { //multi-level sub-answer holder
                        step_answers = Array(fact.step_count).fill('');
                    }
                } else { // get
                    answerItem.input_attempts += 1; // wrong attempt and each step
                    if (answerItem.hasOwnProperty('step_answers')) {
                        step_answers = answerItem['step_answers'];
                    }
                }
                answerItem.correct = fact.is_correct;
                answerItem.attempts = fact.attempt;

                // Check multi-level episodes
                if (step_answers && step_answers.length > 0) { // update multi-level sub-answer list
                    answerItem.step_index = fact.step_index;
                    if (fact.step_index < step_answers.length) {
                        let stepAnswerItem = step_answers[fact.step_index];
                        if (stepAnswerItem === '') {
                            stepAnswerItem = {
                                index: fact.step_index,
                                attempts: 1
                            }
                        } else {
                            stepAnswerItem.attempts += 1
                        }
                        stepAnswerItem.correct = fact.is_correct;
                        step_answers[fact.step_index] = stepAnswerItem;
                    }
                    answerItem['step_answers'] = step_answers;
                }

                if (question_index >= 0) {
                    episodeDetail.answers[question_index] = answerItem;
                } else if (episodeDetail.problem_count === 0) {
                    episodeDetail.answers = [answerItem];
                    episodeDetail.progressCompletion = 0; // infinite game will always show 0;
                }
                if (episodeDetail.problem_count > 0 && fact.is_correct) {
                    if (fact.step_count > 0) {
                        episodeDetail.progressCompletion = fact.problem_index * 100 / fact.problem_count;
                        episodeDetail.progressCompletion += (fact.step_index + 1) * 100 / (fact.step_count * fact.problem_count);
                    } else {
                        episodeDetail.progressCompletion = (fact.problem_index + 1) * 100 / fact.problem_count;
                    }
                    episodeDetail.progressCompletion = Number(episodeDetail.progressCompletion.toFixed(3));
                }

                if(fact.step_count > 0 && fact.step_index > 0) {
                    episodeDetail.playTime += fact.since_question_start_sec * 1000;
                } else {
                    episodeDetail.playTime = fact.since_question_start_sec * 1000;
                }
                break;
            case EpisodeControlEnum.MESSAGES.Tracking.COMPLETED_PROBLEM:
                episodeDetail.type = fact.type;
                /**
                 * Formula fr SMWEB3-9518:
                 * https://docs.google.com/document/d/10UMwLjrSD-JxM3FRy_rDpShlbkSuK_ETECqZoYjDc8A/edit
                 * Accuracy score + Time score
                 * @type {number}
                 *
                 * Accuracy score is accumulated score,
                 * unit accuracy score = grade count * 300;
                 * For episodes with mulitple steps,
                 * unit max grade count is equal to step count per problem.
                 * To avoid the issue, we need to divide step count,
                 * ensure the max grade count per problem is 1.
                 */

                if (this.allowProgressBar) {
                    const {MAX_SEC, MAX_ACCURACY} = this.PROGRESS_BAR.QUESTION;

                    const unitGradeCount = fact.grade_count - (episodeDetail.last_grade_count || 0);
                    const unitAccuracyScore = Math.round(unitGradeCount * MAX_ACCURACY / (fact.step_count || 1));

                    let timeScore = this.getTimeScore(episodeDetail.playTime);
                    episodeDetail.answer_count = fact.problem_index + 1;
                    episodeDetail.time_score += timeScore;
                    episodeDetail.last_grade_count = fact.grade_count; // used to calculate unit grade count
                    episodeDetail.accuracy_score += unitAccuracyScore;

                    episodeDetail.progressScore = episodeDetail.accuracy_score + episodeDetail.time_score;
                    episodeDetail.progressCompletion = Number(Number(episodeDetail.answer_count * 100 / episodeDetail.problem_count).toFixed(3));

                    console.log('PlayerService::: progressScore', fact.grade_count, fact.problem_count, fact.step_count,
                        unitAccuracyScore, episodeDetail.accuracy_score,
                        episodeDetail.playTime, this.questionAnswerTime, timeScore, episodeDetail.time_score)

                    const remainingQuestions = fact.problem_count - episodeDetail.answer_count;
                    this.questionAnswerTime = 0; //-1;
                    this.updateMaxScore(remainingQuestions);
                }
                break;
            case EpisodeControlEnum.MESSAGES.Tracking.FINISH_EPISODE:
                episodeDetail.type = fact.type;
                episodeDetail.progressCompletion = 100;
                this.clearRenderInterval();

                if (this.playerDict && this.userId) { // firebase will quit at this point. manually update the progress bar for mine
                    const myPlayer = this.playerDict[this.userId];
                    myPlayer.progressCompletion = 100;
                    myPlayer.progressScore = this.lastEpisodeItem.progressScore;
                    this.renderPlayerDisplay(myPlayer, this.maxScore, true, this.playerList.length);
                }
                break;
            case EpisodeControlEnum.MESSAGES.Tracking.START_EPISODE:
                episodeDetail.type = fact.type;
                this.questionAnswerTime = 0; //-1;
                break;
            // case EpisodeControlEnum.MESSAGES.Tracking.PRESENT_PROBLEM:
            //     episodeDetail.type = fact.type;
            //     // this.questionAnswerTime = 0;
            //     break;
            case EpisodeControlEnum.MESSAGES.Tracking.ABORT_EPISODE:
                episodeDetail.type = fact.type;
                break;
        }
        episodeDetail.client_time = fact.client_time;
        console.log('PlayerService::: updateEpisodeProgress:', fact, episodeDetail);
    }

    static get allowProgressBar() {
        return (!this.roomData ||
                (this.roomData['host'] !== 'homepage' && this.roomData['disable_arena_progress'] !== true))
            && this.maxScore > 0;
    }

    static avgEpisodePlayTime = 0;

    static updateBotConfig(roomCode, avgMin) {
        if (avgMin > 0) {
            this.avgEpisodePlayTime = avgMin * 60000; // convert min to ms
        }
        let randomSeed = 0;
        if (roomCode && roomCode.trim() !== '') {
            randomSeed = Number(`0.${roomCode}`);
            if (!(randomSeed > 0)) {
                randomSeed = Number(`0.${roomCode.length}`);
            }
        }

        for (const [key, configItem] of Object.entries(this.BOT_CONFIG)) {
            const {time_range, accuracy_range, stars_range} = configItem;
            const [minTime, maxTime] = time_range;
            configItem['time_ratio'] = (randomSeed * (maxTime * 100 - minTime * 100) + minTime * 100) / 100;

            const accuracyIndex = accuracy_range.length > 1 ? Math.floor(randomSeed * accuracy_range.length) : 0;
            configItem['accuracy'] = accuracy_range[accuracyIndex] * this.PROGRESS_BAR.QUESTION.MAX_ACCURACY;

            const starIndex = stars_range.length > 1 ? Math.floor(randomSeed * stars_range.length) : 0;
            configItem['stars'] = stars_range[starIndex];
        }

        console.log('PlayerService::: updateBotConfig(' + randomSeed + ')', this.BOT_CONFIG);
    }

    static getPlayerRef(roomRef, userUid) {
        return (roomRef && userUid) && roomRef.collection('players').doc(userUid);
    }

    static renderPlayersInterval;
    static playerList;
    static hasBots;
    static RENDER_INTERVAL = 1000;

    static renderPlayerList(playerList, mockMaxScore =0) {
        if (!this.allowProgressBar) {
            this.showProgressPanel(false);
            return;
        }

        const playerCount = playerList && playerList.length || 0;
        if (playerCount <= 0) {
            return;
        }

        if (!this.playerDict) {
            this.playerDict = {};
            playerList.sort((a, b) => {
                try{
                    return a.expiresAt.toMillis() - b.expiresAt.toMillis();
                } catch {
                    return 1;
                }
            }).map((player, i) => {
                if (player.isBot) {
                    this.hasBots = true;
                }
                this.playerDict[player.uid] = {
                    uuid: player.uuid,
                    uid: player.uid,
                    isBot: player.isBot,
                    avatar_url: this.getPlayerAvatarImageUrl(i+1)
                };
            });
            this.initContainer();
            this.playerIndexCount = playerList && playerList.length+1 || 1;
        }

        this.playerList = playerList;
        if (this.hasBots) {
            this.playerList.map(player => {
                if (!player.isBot) {
                    if (player.playTime < this.lastEpisodeItem.bestPlayTime) {
                        this.lastEpisodeItem.bestPlayTime = player.playTime;
                    }
                }
            });
        }

        if (!this.renderPlayersInterval) {
            let pointList;
            this.renderPlayersInterval = setInterval(() => {
                if (this.hasBots) {
                    this.updateBotsBaseLine();
                }

                this.updateMaxScore();
                const playerCount = this.playerList.length;
                let allCompleted = true, mineCompleted = false, isMe = false, myPoint = 0;
                pointList = [];
                this.playerList.map(player => {
                    if (!this.playerDict.hasOwnProperty(player.uid)) {
                        this.playerDict[player.uid] = {
                            uuid: player.uuid,
                            uid: player.uid,
                            isBot: player.isBot,
                            avatar_url: this.getPlayerAvatarImageUrl()
                        };
                    }
                    if (!(player.progressCompletion >= 100)) {
                        allCompleted = false;
                    }
                    isMe = player.uid === this.userId;
                    if(isMe) {
                        mineCompleted = player.progressCompletion >= 100;
                    }
                    this.renderPlayerDisplay(player, this.maxScore, isMe, playerCount);

                    try {
                        if(this.playerDict[player.uid]) {
                            if(isMe) {
                                myPoint = this.playerDict[player.uid].progressScore;
                            }
                            pointList.push(this.playerDict[player.uid].progressScore);
                        }
                    } catch{}
                });
                const scoreElement = document.getElementById('my-player-question-count');
                if (scoreElement && pointList.length > 0) {
                    const myOrder = pointList.sort((a, b) => (b-a)).indexOf(myPoint) + 1;
                    scoreElement.innerHTML = `<span class="index-style">${myOrder}</span>/${playerCount}`;
                }
                console.log('PlayerService::: renderPlayerList', this.maxScore, allCompleted, mineCompleted, pointList);

                if (allCompleted || mineCompleted || (mockMaxScore > 0 && this.questionAnswerTime > 5 * this.RENDER_INTERVAL)) {
                    this.clearRenderInterval();
                }
                if (this.questionAnswerTime >= 0) {
                    this.questionAnswerTime += this.RENDER_INTERVAL;
                }
            }, this.RENDER_INTERVAL);
        }
    }

    static clearRenderInterval() {
        if (this.renderPlayersInterval) {
            clearTimeout(this.renderPlayersInterval);
            this.renderPlayersInterval = undefined;
        }
    }

    /** Please refer to
     https://docs.google.com/document/d/10UMwLjrSD-JxM3FRy_rDpShlbkSuK_ETECqZoYjDc8A/edit
     */
    static avgAnswerTime = 0;
    static defaultAnswerTime = 0;
    static DEFAULT_AVG_TIME_PER_PROBLEM = 40000; //40 sec
    static updateBotsBaseLine() {
        const {problem_count, bestPlayTime} = this.lastEpisodeItem;

        if (this.avgAnswerTime <= 0) {
            if (this.avgEpisodePlayTime > 0) {
                this.avgAnswerTime = this.avgEpisodePlayTime / problem_count;
            } else {
                this.avgAnswerTime = this.DEFAULT_AVG_TIME_PER_PROBLEM;
            }
        }
        this.defaultAnswerTime = this.avgAnswerTime;

        if (bestPlayTime > 0 && bestPlayTime * 1.5 < this.avgAnswerTime) {
            this.avgAnswerTime = (bestPlayTime + this.avgAnswerTime) / 2;
        }
    }

    static getBotTypeConfig(player) {
        if (player && player.isBot && player.botType && this.BOT_CONFIG.hasOwnProperty(player.botType)) {
            return this.BOT_CONFIG[player.botType];
        }
        return null;
    }

    static updateBotProgress(botTypeConfig, player) {
        if (botTypeConfig) {
            const {time_ratio, accuracy, stars} = botTypeConfig;
            const botPlayTime = Math.round(time_ratio * this.avgAnswerTime);
            const {problem_count} = this.lastEpisodeItem;

            let progressScore = player.progressScore || 0;
            let progressCompletion = player.progressCompletion || 0;
            let answer_count = player.answer_count || 0;
            let playTime = (player.playTime || 0) + this.RENDER_INTERVAL;
            let time_spent = player.time_spent || 0;

            if (progressCompletion < 100) {
                time_spent += this.RENDER_INTERVAL;
                if (playTime >= botPlayTime) {
                    playTime -= botPlayTime;
                    const timeScore = this.getTimeScore(botPlayTime);
                    progressScore += (accuracy + timeScore);
                    answer_count += 1;
                    progressCompletion = Number(Number(player.answer_count * 100 / problem_count).toFixed(3));
                }

                if (this.bots_progress && this.bots_progress[player.uid]) {
                    this.bots_progress[player.uid]['progressCompletion'] = progressCompletion;
                    this.bots_progress[player.uid]['progressScore'] = progressScore;
                    this.bots_progress[player.uid]['time_spent'] = time_spent;
                } else {
                    if (!this.bots_progress) {
                        this.bots_progress = {};
                    }
                    const defaultBotPlayTime = Math.round(time_ratio * this.defaultAnswerTime);
                    const totalProgressScore = (this.getTimeScore(defaultBotPlayTime) + accuracy) * problem_count;
                    const totalTimeSpent = defaultBotPlayTime * problem_count;

                    this.bots_progress[player.uid] = {
                        uuid: player.uuid,
                        uid: player.uid,
                        score: stars,
                        progressCompletion,
                        progressScore,
                        time_spent,
                        totalProgressScore,
                        totalTimeSpent
                    };
                }
            }

            return {
                playTime,
                progressScore,
                progressCompletion,
                answer_count,
                time_spent,
            };
        }
        return null;
    }

    static get progressComponent() {
        return document.getElementById(this.PROGRESS_BAR.COMPONENT);
    }

    static delayShowProgressBar;

    static initContainer() {
        if (!this.progressComponent) {
            return;
        }

        this.showProgressPanel(true);

        const closeBtn = document.getElementById('progress-close-btn');
        closeBtn.onclick = () => {
            this.expandProgressBar(false);
        }
        const openBtn = document.getElementById('progress-open-btn');
        openBtn.onclick = () => {
            this.expandProgressBar(true);
        }

        window.onclick = (e) => {
            if (!PlayerService.lastTipElementId) {
                return;
            }
            if (!e || !e.target || e.target.className.indexOf('username-tip-ref') < 0) {
                PlayerService.hideToolTip();
            }
        }

        window.onblur = (e) => {
            PlayerService.hideToolTip();
        }

        this.delayShowProgressBar = setTimeout(() => {
            this.expandProgressBar(true, true);
            clearTimeout(this.delayShowProgressBar);
        }, 1000);
    }

    static hideToolTip() {
        if (PlayerService.lastTipElementId) {
            const lastTipElement = document.getElementById(PlayerService.lastTipElementId);
            if (lastTipElement) {
                lastTipElement.classList.remove('show');
                PlayerService.lastTipElementId = undefined;
            }
        }
    }

    static barExpand = false;
    static minimizeView;
    static expandView;
    static delayRemoveAnimate;

    static expandProgressBar(isExpand, initial = false) {
        this.showProgressPanel(true);
        if (!this.minimizeView) {
            this.minimizeView = document.getElementById('progress-minimize-view');
        }
        if (!this.expandView) {
            this.expandView = document.getElementById('progress-expand-view');
        }

        if (!this.expandView || !this.minimizeView) {
            return;
        }

        const animateClass = (isExpand !== this.barExpand) ? 'animate' : '';
        if (isExpand) {
            if (initial) {
                this.expandView.className = `initial open ${animateClass}`;
            } else {
                this.expandView.className = `open ${animateClass}`;
            }
            this.minimizeView.className = animateClass;
        } else {
            this.expandView.className = animateClass;
            this.minimizeView.className = `open ${animateClass}`;
        }
        if (this.delayRemoveAnimate) {
            clearTimeout(this.delayRemoveAnimate);
        }
        this.delayRemoveAnimate = setTimeout(this.removeAnimate.bind(this), 1200);
        this.barExpand = isExpand;
    }

    static removeAnimate() {
        if (this.delayRemoveAnimate) {
            clearTimeout(this.delayRemoveAnimate);
        }
        if (this.expandView) {
            this.expandView.classList.remove('animate');
        }
        if (this.minimizeView) {
            this.minimizeView.classList.remove('animate');
        }
    }

    static showProgressPanel(show) {
        if (!this.allowProgressBar && show) {
            return;
        }
        try {
            const progressPanel = document.getElementById(this.PROGRESS_BAR.PANEL);
            if (progressPanel) {
                progressPanel.className = show ? '' : 'hide';
            }
        } catch {}
    }

    /**
     * @param playerIndex
     * playerIndex: start from zero.
     * image index start from 1, allow range [1, 60];
     * @returns aircraft avatar url {string|null}
     */
    static getPlayerAvatarImageUrl(playerIndex = -1) {
        let imageIndex = playerIndex;
        if (imageIndex < 0) {
            imageIndex = this.playerIndexCount;
            this.playerIndexCount += 1;
        }

        // imageIndex 1-60;
        if (imageIndex >= 59) {
            imageIndex = imageIndex % 59 + 1;
        }

        if(env.aircraftUrl && env.aircraftUrl !== '') {
            return `${env.aircraftUrl}images/aircraft-avatars/avatar_${imageIndex}.png`;
        } else {
            return null;
        }
    }

    static renderPlayerDisplay(player, maxScore, isMe, playerCount) {
        if (!this.progressComponent) {
            return;
        }
        let playerContainerElement, elemClassName = 'username-tip-ref player-item-container';

        const elementId = "player-" + player.uid;

        const oldPlayerData = Object.assign({}, this.playerDict[player.uid]);
        player.avatar_url = oldPlayerData.avatar_url;

        const botConfigItem = this.getBotTypeConfig(player);
        if (botConfigItem) { // bot will update based on stored data;
            const botData = this.updateBotProgress(botConfigItem, oldPlayerData);
            if (botData) {
                player = {...player, ...botData};
                console.log('bot[' + player.username + ']', player.progressScore, player.progressCompletion, player.time_spent)
            }
            this.playerDict[player.uid] = player;
        }

        let progressValue = player.hasOwnProperty('progressScore') && maxScore > 0 ?
            Math.round(player.progressScore * 100 / maxScore) : 0;

        if (progressValue >= 95) {
            if (player.progressCompletion >= 100 ||
                (this.lastEpisodeItem && this.lastEpisodeItem.progressCompletion >= 100)) {
                if (progressValue > 100) {
                    progressValue = 110;
                }
            } else {
                progressValue = 95;
            }
        }

        player.progressValue = progressValue;

        if (oldPlayerData &&
            player.progressScore === oldPlayerData.progressScore &&
            player.progressValue === oldPlayerData.progressValue) {
            return;
        }

        if (isMe) {
            elemClassName += ' mine';
            if (player.progressScore > 0) {
                const oldProgressScore = oldPlayerData && oldPlayerData.progressScore || 0;
                const oldAccuracyScore = oldPlayerData && oldPlayerData.accuracyScore || 0;
                console.log('PlayerService::: oldAccuracyScore', oldAccuracyScore, player.accuracyScore);
                if (player.accuracyScore > oldAccuracyScore) {
                    const appendScore = player.progressScore - oldProgressScore;
                    if (appendScore > 0) {
                        this.showScoreAnimation(appendScore);
                    }
                }
            }
        }

        playerContainerElement = document.getElementById(elementId);
        if (!playerContainerElement) {
            playerContainerElement = document.createElement("div");
            playerContainerElement.id = elementId;
            playerContainerElement.className = elemClassName;
            playerContainerElement.style.marginLeft = `calc(${progressValue}% - 6px)`;
            if (isMe) {
                playerContainerElement.style.zIndex = playerCount;
            }

            const finalElement = this.setUpPlayerDisplayElement(player, playerContainerElement, isMe);

            this.progressComponent.appendChild(finalElement);
            if (isMe) {
                this.renderMyPlayerDisplay(player);
            }
        } else {
            playerContainerElement.style.marginLeft = `calc(${progressValue}% - 6px)`;
        }
        console.log('PlayerService::: renderPlayer[' + player.username + `]${isMe ? '(me)' : ''}:`, player.progressValue,
            player.progressScore, player.progressCompletion);
        this.playerDict[player.uid] = player;
    }

    static renderMyPlayerDisplay(player) {
        const playerContainerElement = document.createElement("div");
        playerContainerElement.id = "my-player-item";
        playerContainerElement.className = 'my-player-container';

        const finalElement = this.setUpPlayerDisplayElement(player, playerContainerElement);

        console.log('PlayerService::: renderMyPlayerDisplay:::', player);

        const myPlayerHolder = document.getElementById(this.PROGRESS_BAR.USER_CONTAINER_ID);
        if (myPlayerHolder) {
            myPlayerHolder.appendChild(finalElement);
        }
    }

    static lastTipElementId;
    static setUpPlayerDisplayElement(player, playerContainerElement, isMe = false) {
        const playerElement = document.createElement("div");

        let avatarFaceImage;
        if(this.playerStudentAvatars && this.playerStudentAvatars.hasOwnProperty(player.uid)) {
            avatarFaceImage = this.playerStudentAvatars[player.uid];
        }

        // console.log('setUpPlayerDisplayElement', player);

        let tooltipElement, avatarElement;
        if (avatarFaceImage) {
            playerElement.className = 'username-tip-ref avatar-holder';

            avatarElement = document.createElement('img');
            avatarElement.id = 'student-avatar-' + player.uid;
            avatarElement.className = 'username-tip-ref student-avatar';
            avatarElement.src = "data:image/png;base64, " + avatarFaceImage;
            playerElement.appendChild(avatarElement);

            tooltipElement = document.createElement('div');
            tooltipElement.id = 'player-tooltip-' + player.uid;
            tooltipElement.className = 'username-tip-ref player-tooltip';
            tooltipElement.innerText = player.username;
        } else if (player.avatar_url && player.avatar_url !== '') { // use arena waiting room image
            playerElement.className = 'username-tip-ref player-holder';

            avatarElement = document.createElement('img');
            avatarElement.id = 'player-avatar-' + player.uid;
            avatarElement.className = 'username-tip-ref player-avatar';
            avatarElement.src = player.avatar_url;
            avatarElement.width = 30;
            avatarElement.height = 30;
            playerElement.appendChild(avatarElement);

            tooltipElement = document.createElement('div');
            tooltipElement.id = 'player-tooltip-' + player.uid;
            tooltipElement.className = 'username-tip-ref player-tooltip';
            tooltipElement.innerText = player.username;
        } else {
            playerElement.className = 'username-tip-ref player-holder';

            const nameElement = document.createElement('label');
            nameElement.id = 'name';
            nameElement.className = 'username-tip-ref player-name'
            nameElement.innerText = this.getNameInitial(player.username);
            playerElement.appendChild(nameElement);
        }
        playerContainerElement.appendChild(playerElement);
        if(tooltipElement) {
            playerContainerElement.ontouchstart = (e) => {
                e.preventDefault();
                if (PlayerService.lastTipElementId === tooltipElement.id) {
                    PlayerService.lastTipElementId = undefined;
                    tooltipElement.classList.remove('show');
                    return;
                } else {
                    PlayerService.hideToolTip();
                }

                PlayerService.lastTipElementId = tooltipElement.id;
                tooltipElement.classList.add('show');
            }

            playerElement.onmousedown = playerElement.onmouseover = () => {
                tooltipElement.classList.add('show');
            }

            playerElement.onmouseout = playerElement.onmouseup = () => {
                tooltipElement.classList.remove('show');
            }

            playerContainerElement.appendChild(tooltipElement);
        }
        return playerContainerElement;
    }

    static getNameInitial(name) {
        if (!name || name === '') {
            return name;
        }
        const nameList = name.trim().split(' ');
        let initials = nameList.reduce((output, name) => {
            if (name.length > 0) {
                output += name.substring(0, 1);
            }
            return output;
        }, '');
        if (initials.length > 2) {
            initials = initials.substring(0, 2);
        }
        return initials;
    }

    static scoreAnimateElements = [];
    static scoreItemCount = 0;

    static showScoreAnimation(value) {
        if (value <= 0) {
            return;
        }
        // TODO After every question is complete by “you”,
        // can we show the points they got show up and then fly away?
        const container = document.getElementById(this.PROGRESS_BAR.PANEL);
        if (!container) {
            console.log('PlayerService::: showScoreAnimation failed 1:', value);
            return;
        }
        console.log('PlayerService::: showScoreAnimation:', value);
        const labelElement = document.createElement('label');
        labelElement.className = 'append-score-label';
        labelElement.innerText = `+${value}`;

        const scoreElement = document.createElement('div');
        scoreElement.id = 'score-element-item-' + this.scoreItemCount;
        scoreElement.className = 'append-score-box show';
        scoreElement.appendChild(labelElement);
        container.appendChild(scoreElement);
        this.scoreItemCount += 1;

        const timeoutId = setTimeout(() => {
            const scoreElementItem = this.scoreAnimateElements[this.scoreAnimateElements.length - 1];
            clearTimeout(scoreElementItem.timeoutId);
            const scoreElement = document.getElementById(scoreElementItem.id);
            if (scoreElement) {
                scoreElement.remove();
            }
            this.scoreAnimateElements.pop();
        }, 4000);

        this.scoreAnimateElements.unshift({id: scoreElement.id, timeoutId});
    }

    static LIVECLASSROOM_EVENT_ITEM_FIELDS = ['type', "episode_slug",
        "problem_count", "step_count", "problem_index", "step_index",
        "is_correct", "attempt", "mistakes",
        "episode_name", "episode_type", "activity_context", "client_time"];

    static convertFirebaseEventItem(fact, klass_id, student_id, expires_at) {
        if (fact) {
            return this.LIVECLASSROOM_EVENT_ITEM_FIELDS.reduce((output, key) => {
                if (fact.hasOwnProperty(key)) {
                    output[key] = fact[key];
                }
                return output;
            }, {
                klass_id,
                student_id,
                expires_at
            });
        }
        return null;
    }

    static get liveclassFirestore() {
        return this.firestore.db.collection('live-classes');
    }

    static getRoomParams(arenaRoomFirebaseUrl) {
        const roomParams = arenaRoomFirebaseUrl && arenaRoomFirebaseUrl.split('/') || null;
        if (roomParams && roomParams.length >= 4) {
            return roomParams;
        }
        return null;
    }

    static getRoomRef(roomPath) {
        console.log('PlayerService::: getRoomRef', roomPath);
        const roomParams = this.getRoomParams(roomPath);
        const [arenaFieldName, hostUserId, roomFieldName, roomUuid] = roomParams;
        this.updateQuickSightReportData(this.QUICKSIGHT_FIELDS.HOST_USERID, hostUserId);
        this.updateQuickSightReportData(this.QUICKSIGHT_FIELDS.ROOM_ID, roomUuid);
        return this.firestore.db.collection(arenaFieldName)
            .doc(hostUserId)
            .collection(roomFieldName)
            .doc(roomUuid);
    }

    static QUICKSIGHT_FIELDS = {
        HOST_USERID : 'uuid',
        ROOM_ID: 'doc_id',
        HOST_FROM : 'host_site',
        PRIVATE_ROOM: 'private_room',
        ROOM_LOCALE: 'arena_locale',
        SHOW_PROGRESS: 'show_progress'
    };

    static _quickSightReportData;
    static get quickSightReportData() {
        if(this._quickSightReportData &&
            (!this._quickSightReportData.hasOwnProperty(this.QUICKSIGHT_FIELDS.SHOW_PROGRESS))) {
            this._quickSightReportData[this.QUICKSIGHT_FIELDS.SHOW_PROGRESS] = this.allowProgressBar;
        }
        return this._quickSightReportData;
    }

    static updateQuickSightReportData(key, value) {
        if(!this._quickSightReportData) {
            this._quickSightReportData = {};
        }
        this._quickSightReportData[key] = value;
    }

    static get firestore() {
        return window.matificFirestoreService;
    }

    static get userId() {
        let idValue;
        if(this._myUid) {
            return this._myUid;
        }
        try {
            idValue = this.firestore.matificUser.user_id;
        } catch (e) {
            console.log(e);
        }

        if (!idValue && this.firestore && this.firestore.user) {
            idValue = this.firestore.user.uid;
        }
        console.log('userId=', idValue);
        this._myUid = idValue;
        return idValue;
    }

    static get klassId() {
        let idValue;
        try {
            idValue = this.firestore.matificUser.klass_id;
        } catch(e) {
            console.log(e);
        }
        console.log('klassId=', idValue);
        return idValue;
    }

    static _myUid;
    static loadFireBase(scriptUrl, firebaseSettings, myUserId, allowAnonymous = false) {
        this._myUid = myUserId;
        if (this.firestore && this.firestore.isReady) {
            return Promise.resolve(true);
        } else if (this.firestore) {
            return this.initFirebaseWithSetting(firebaseSettings, allowAnonymous);
        } else {
            return this.loadScript(scriptUrl, this.FIRESTORE_SCRIPT_ID).then(succeed => {
                return this.initFirebaseWithSetting(firebaseSettings, allowAnonymous);
            });
        }
    }

    static initFirebaseWithSetting(settings, allowAnonymous = false) {
        if(!settings || !settings.firebase_config) {
            return this.getFirebaseSettings(allowAnonymous).then(settings => {
                return this.firestore.initFireBase(settings).then(() => {
                    if (this.firestore.token) {
                        return true;
                    } else {
                        return Promise.reject(false);
                    }
                });
            });
        } else if((!settings.user_token) && (!settings.is_multi_enabled)) {
            return this.getDefaultFirebaseToken(allowAnonymous).then(token => {
                settings.user_token = token;
                return this.firestore.initFireBase(settings).then(() => {
                    if (this.firestore.token) {
                        return true;
                    } else {
                        return Promise.reject(false);
                    }
                });
            });
        } else {
            return this.firestore.initFireBase(settings).then(succeed => {
                if(!this.firestore.app) {
                    return Promise.reject(false);
                } else if (this.firestore.token) {
                    return true;
                } else { // re-auth token if it is expired
                    if(settings.is_multi_enabled) {
                        return this.getFirebaseSettings(allowAnonymous).then(settings => {
                            return this.firestore.authFirebase(settings);
                        });
                    } else {
                        return this.getDefaultFirebaseToken(allowAnonymous).then(user_token => {
                            return this.firestore.authFirebase({user_token});
                        });
                    }
                }
            });
        }
    }

    static loadScript(scriptUrl, elemID) {
        return new Promise((resolve, reject) => {
            let scriptTargetEl = document.getElementById(elemID);
            if (scriptTargetEl) { // load failed reload the script
                scriptTargetEl.remove();
            }

            if (scriptUrl) {
                let scriptEl = document.createElement('script');
                scriptEl.async = true;
                scriptEl.src = scriptUrl;
                scriptEl.id = elemID;
                scriptEl.onload = () => {
                    resolve(true);
                };
                scriptEl.onerror = () => {
                    console.log('PlayerService::: loadScript failed', scriptUrl);
                    reject(false);
                };
                document.head.appendChild(scriptEl);
            } else {
                console.log('PlayerService::: loadScript missing', scriptUrl);
                reject(false);
            }
        });
    }

    // move get token due to require jquery lib
    static getDefaultFirebaseToken() {
        return new Promise((resolve, reject) => {
            const res = AjaxService.get_with_credentials(this.defaultFirebaseTokenEndpoint, 'get firebase JWT token');
            if (AjaxService.isRequestFail(res)) {
                if (allowAnonymous) {
                    console.log("PlayerService::: getFirebaseToken failed Anonymous null");
                    resolve(null);
                } else {
                    console.log("PlayerService::: getFirebaseToken failed");
                    reject(null);
                }
            } else {
                console.log('PlayerService::: getFirebaseToken succeed', res && res.responseJSON);
                resolve(res.responseJSON);
            }
        })
    }

    static get defaultFirebaseTokenEndpoint() {
        let originUrl;
        if (window.location.origin.includes('http://localhost')) {
            originUrl = 'http://localhost:8000';
        } else {
            originUrl = window.location.origin;
        }
        return `${originUrl}/api/v2/accounts/firebase-token/`;
    }

    static getFirebaseSettings(allowAnonymous = false) {
        return new Promise((resolve, reject) => {
            const res = AjaxService.get_with_credentials(this.firebaseConfigEndpoint, 'get firebase setting JWT');
            if (AjaxService.isRequestFail(res)) {
                if (allowAnonymous) {
                    console.log("PlayerService::: getFirebaseSetting failed Anonymous null");
                    resolve(null);
                } else {
                    console.log("PlayerService::: getFirebaseSetting failed");
                    reject(null);
                }
            } else {
                console.log('PlayerService::: getFirebaseSetting succeed', res && res.responseJSON);
                resolve(res.responseJSON);
            }
        })
    }

    static get firebaseConfigEndpoint() {
        let originUrl;
        if (window.location.origin.includes('http://localhost')) {
            originUrl = 'http://localhost:8000';
        } else {
            originUrl = window.location.origin;
        }
        return `${originUrl}/api/v2/accounts/firebase-config/`; // new api
    }
}

export class UtilService {

    static getCurrentUrl() {
        return location.href;
    }

    static getQuery(q) {
        if (!q || q.trim() == '') {
            return null;
        }
        return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [, null])[1];
    }

    static isLocalhost() {
        return location.origin.indexOf('http://localhost') >= 0 ||
            location.origin.indexOf('http://127.0.0.1') >= 0;
    }

    static randomNum(min, max) {
        return Math.random() * (max - min) + min;
    }

    static isNil(value) {
        return value === null || value === undefined;
    }

    static snakeCase(string) {
        return string.replace(/\W+/g, " ")
            .split(/ |\B(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join('_');
    }
}
